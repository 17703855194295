let intervalIds = {};

// Предзагрузка изображений
const preloadImages = (images) => {
  images.forEach((src) => {
    const img = new Image();
    img.src = src;
  });
};

export const startSlideshow = (id, images) => {
  let index = 0;

  // Предзагружаем все изображения
  preloadImages(images);

  const element = document.getElementById(id);
  if (!element) return;

  stopSlideshow(id); // Останавливаем старый слайдшоу, если есть

  intervalIds[id] = setInterval(() => {
    element.src = images[index];
    index = (index + 1) % images.length;
  }, 500);

  element.src = images[0];
};

export const stopSlideshow = (id) => {
  if (intervalIds[id]) {
    clearInterval(intervalIds[id]);
    delete intervalIds[id];
  }

  const element = document.getElementById(id);
  if (element) {
    element.src = ""; // Очищаем, возвращаем к черному фону
  }
};
