import React from "react";

function AboutMeSection() {
  return (
    <section className="bg-customGray  text-white py-20 px-10">
      <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center space-y-10 md:space-y-0">
        {/* Фото слева */}
        <div className="flex-shrink-0">
          <img
            src="/images/maksim.jpg"
            alt="Максим"
            className="w-64 h-84 object-cover rounded-md"
          />
        </div>
        {/* Текст справа */}
        <div className="md:ml-10">
          <h2 className="text-4xl font-bold mb-4">Maksim Repkin</h2>
          <p className="mb-4 text-lg">UAB Repta, direktorius</p>
          <p className="mb-4 text-lg">
            Repta specializuojasi modernių svetainių, logotipų, firminio
            stiliaus ir prekių ženklų dizaino srityje. Mes taip pat kuriame
            verslui skirtus elementus: nuo vizitinių kortelių ir meniu
            restoranams iki etikečių ir pakuočių, siūlydami kiekvienam projektui
            pritaikytus sprendimus
          </p>
          <p className="text-lg">
            Mūsų tikslas - kad Jūsų projektai būtų gražūs, funkcionalūs ir
            patogūs Jūsų klientams
          </p>
        </div>
      </div>
    </section>
  );
}

export default AboutMeSection;
