import React from "react";
import { Link } from "react-router-dom";
import "../styles/Footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-logo">
        <img
          src="/images/repta_black-1.png"
          alt="Логотип компании"
          className="logo-image"
        />
      </div>
      <div className="footer-columns">
        {/* Первый столбик: Меню */}
        <div className="footer-column">
          <h3 className="column-title">Meniu</h3>
          <ul>
            <li>
              <Link to="/" className="navLink">
                Home
              </Link>
            </li>
            <li>
              <Link to="/services" className="navLink">
                Susisiekti
              </Link>
            </li>
            <li>
              <Link to="/portfolio" className="navLink">
                Portfolio
              </Link>
            </li>
            <li>
              <Link to="/about" className="navLink">
                Apie mus
              </Link>
            </li>
            <li>
              <Link to="/contact" className="navLink">
                Kontaktai
              </Link>
            </li>
          </ul>
          <button className="lang-switch">
            <a
              href="https://repta.eu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              EN
            </a>
          </button>
        </div>

        {/* Второй столбик: Социальные сети */}
        <div className="footer-column">
          <h3 className="column-title">Socialiniai tinklai</h3>
          <ul>
            <li>
              <a
                href="https://www.instagram.com/repta.tech/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </li>
          </ul>
        </div>

        {/* Третий столбик: Реквизиты */}
        <div className="footer-column">
          <h3 className="column-title">Rekvizitai</h3>
          <ul>
            <li>UAB Repta</li>
            <li>Įm. kodas: 306640177</li>
            <li>Adresas: Leičių g. 13-84,</li>
            <li>LT-12109 Vilnius</li>
          </ul>
        </div>

        {/* Четвертый столбик: Контакты */}
        <div className="footer-column">
          <h3 className="column-title">Kontaktai</h3>
          <ul>
            <li>Telefonas: +370 672 78 274</li>
            <li>Telefonas: +370 600 17 619</li>
            <li>
              Email: <a href="mailto:info@repta.com">info@repta.lt</a>
            </li>
            <li className="footer-author">© 2024 UAB Repta</li>
            <li className="footer-author">All rights reserved</li>
            <li className="footer-author">Designed by UAB Repta</li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
