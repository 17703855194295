import React from "react";
import "../styles/Contacts.scss";

const Contacts = () => {
  const contactData = [
    {
      title: "Mūsų kontaktai",
      content: [
        { label: "Telefonas", value: "+370 672 78274" },
        { label: "Telefonas", value: "+370 600 17619" },
        { label: "El. paštas", value: "info@repta.lt" },
      ],
    },
    {
      title: "Adresas",
      content: [
        { label: "Adresas", value: "Leičių g. 13-84, LT-12109 Vilnius" },
      ],
    },
    {
      title: "Rekvizitai",
      content: [
        { label: "Įmonės pavadinimas", value: "UAB REPTA" },
        { label: "Įmonės kodas", value: "306640177" },
      ],
    },
    {
      title: "Buhalterija",
      content: [{ label: "El. paštas", value: "buhalterija@repta.lt" }],
    },
  ];

  return (
    <section className="contacts-section">
      {/* Заголовок */}
      <div className="contacts-header">
        <h1>Kontaktai</h1>
      </div>

      {/* Квадратики */}
      <div className="contacts-grid">
        {contactData.map((item, index) => (
          <div className="contact-card" key={index}>
            <h2 className="contact-title">{item.title}</h2>
            <ul className="contact-content">
              {item.content.map((entry, idx) => (
                <li key={idx}>
                  <span className="contact-label">{entry.label}:</span>{" "}
                  <span className="contact-value">{entry.value}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Contacts;
