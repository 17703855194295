import React from "react";
import "../styles/ContactUs.scss";

const ContactUs = () => {
  return (
    <section className="contact-us">
      {/* Заголовок */}
      <div className="contact-header">
        <h1 className="contact-title">?</h1>
        <p className="contact-subtitle">
          Pasakykite mums daugiau apie save ir savo projektą
        </p>
      </div>

      {/* Форма */}
      <form
        action="https://formspree.io/f/xovqbeve" // Замените на ваш Formspree endpoint
        method="POST"
        className="contact-form"
      >
        {/* Выбор услуги */}
        <label htmlFor="service">Pasirinkite paslaugą:</label>
        <select id="service" name="service" required>
          <option value="">Pasirinkite</option>
          <option value="website">Interneto svetainių kūrimas</option>
          <option value="logo">Logotipų dizainas</option>
          <option value="branding">Firminis verslo stilius</option>
        </select>

        {/* Детали проекта */}
        <label htmlFor="projectDetails">Aprašykite projektą:</label>
        <textarea
          id="projectDetails"
          name="projectDetails"
          placeholder="Įrašykite projekto detales..."
          required
        ></textarea>

        {/* Контактная информация */}
        <label htmlFor="name">Jūsų vardas:</label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Įrašykite savo vardą..."
          required
        />

        {/* Контактная информация */}
        <label htmlFor="name">Įmones arba projekto pavadinimas:</label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Įrašykite įmones arba projekto pavadinimą..."
          required
        />

        <label htmlFor="email">El. paštas:</label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Įrašykite savo el. paštą..."
          required
        />

        {/* Как узнали */}
        <label htmlFor="referral">Kaip apie mus sužinojote?</label>
        <select id="referral" name="referral" required>
          <option value="">Pasirinkite</option>
          <option value="instagram">Instagram</option>
          <option value="google">Suradote internete</option>
          <option value="recommendation">Rekomendacijos</option>
          <option value="ads">Reklamos portalai (pvz. skelbiu.lt)</option>
        </select>

        {/* Кнопка отправки */}
        <button type="submit" className="submit-button">
          Siųsti užklausą
        </button>
      </form>
    </section>
  );
};

export default ContactUs;
