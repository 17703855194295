import React from "react";
import "../styles/CallToActionSection.scss";

const CallToActionSection = () => {
  return (
    <section className="call-to-action-section">
      <div className="text-container">
        <p className="highlighted-text">
          Jei ieškote stipraus partnerio, kuris atiduotų visas jėgas Jūsų
          projektui,
        </p>
        <p className="highlighted-text_2">Mes esame Jūsų pasirinkimas</p>
      </div>
    </section>
  );
};

export default CallToActionSection;
