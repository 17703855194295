import React from "react";
import "../styles/About.scss";

const AboutUs = () => {
  return (
    <section className="about-section">
      {/* Заголовок */}
      <div className="about-header">
        <h1>APIE MUS</h1>
      </div>

      {/* Первый блок: текст слева, фотография справа */}
      <div className="content-block">
        <div className="text">
          <h2>Mūsų vizija</h2>
          <p>
            Tikime komandinio darbo, kūrybiškumo ir atkaklumo galia. Šios
            savybės padeda mums pasiekti aukštų rezultatų ir augti kartu su
            klientais.
          </p>
          <p>
            Jei ieškote partnerio, kuris būtų pasirengęs į kiekvieną projektą
            įdėti visas savo jėgas, mes esame Jūsų pasirinkimas.
          </p>
        </div>
        <div className="image">
          <img
            src="/images/repta_black-1.png"
            alt="Įmonės logotipas"
            className="round-image"
          />
        </div>
      </div>

      {/* Второй блок: фотография слева, текст справа */}
      <div className="content-block reverse">
        <div className="image">
          <img
            src="/images/maksim-removebg-preview.png"
            alt="Mūsų direktorius"
            className="portrait-image"
          />
        </div>
        <div className="text">
          <h2>Mūsų istorija</h2>
          <p>
            Esame jauna ir energinga įmonė, kuri buvo įkurta 2023 m. Nepaisant
            trumpos kelionės, sparčiai tobulėjame ir užtikrintai pelnime klientų
            pasitikėjimą. Kiekvienas naujas projektas mums yra galimybė parodyti
            savo talentus ir įrodyti, kad net ir jauna įmonė gali pasiūlyti
            kokybiškus sprendimus.
          </p>
          <p>
            Mūsų požiūris grindžiamas nuolatiniu mokymųsi ir tobulumo siekimu.
            Sekame naujausias interneto kūrimo tendencijas ir jas diegiame savo
            darbe. Svarbu, kad kiekviena mūsų sukurta svetainė atitiktų ne tik
            šiuolaikinius standartus, bet ir atneštų realią naudą mūsų
            klientams.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
